import { Injectable } from '@angular/core';
import {
  Subscription,
  of,
  from,
  Observable,
  combineLatest,
  shareReplay,
  catchError,
  concatMap,
  map,
  first,
  EMPTY
} from 'rxjs';
import { Auth0Client, createAuth0Client, GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import { environment } from '../../../environments/environment';
import { Auth0ConnectionsEnum, Auth0LoginErrorEnum } from '../models/user.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  refreshSub: Subscription;
  auth0Client$: Observable<Auth0Client> = from(
    createAuth0Client({
      domain: environment.auth0.domain,
      clientId: environment.auth0.client_id,
      authorizationParams: {
        redirectUri: environment.auth0.redirect_uri,
        audience: environment.auth0.audience
      },
      useRefreshTokens: true
    })
  ).pipe(shareReplay(1));
  isAuthenticated$ = this.auth0Client$.pipe(concatMap((client: Auth0Client) => from(client.isAuthenticated())));
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback<{ target: string }>()))
  );

  getTokenSilently$(): Observable<GetTokenSilentlyVerboseResponse> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        from(client.getTokenSilently({ detailedResponse: true })).pipe(
          catchError(e => {
            if (e.error === 'missing_refresh_token' || e.error === 'invalid_grant') {
              client.loginWithRedirect({
                authorizationParams: {
                  redirect_uri: environment.auth0.redirect_uri,
                  connection:
                    sessionStorage.getItem('connection-type') == 'transient'
                      ? Auth0ConnectionsEnum.transient
                      : Auth0ConnectionsEnum.default
                },
                appState: { target: location.pathname }
              });
              return EMPTY;
            }
          })
        )
      )
    );
  }

  login(connection?: Auth0ConnectionsEnum, target?: string): Observable<void> {
    if (connection === Auth0ConnectionsEnum.transient) {
      sessionStorage.setItem('connection-type', 'transient');
    } else if (connection === Auth0ConnectionsEnum.default) {
      sessionStorage.setItem('connection-type', 'default');
    }
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) =>
        client.loginWithRedirect({
          authorizationParams: {
            redirect_uri: environment.auth0.redirect_uri,
            connection:
              sessionStorage.getItem('connection-type') == 'transient'
                ? Auth0ConnectionsEnum.transient
                : Auth0ConnectionsEnum.default
          },
          appState: { target: target || location.pathname }
        })
      )
    );
  }

  handleAuthCallback(): Observable<{ loggedIn: boolean; targetUrl: string; errorType?: Auth0LoginErrorEnum }> {
    return combineLatest({
      params: of(new URL(document.location.href).searchParams),
      redirectResponse: this.handleRedirectCallback$.pipe(
        concatMap(result => this.isAuthenticated$.pipe(map(loggedIn => ({ appState: result.appState, loggedIn }))))
      )
    }).pipe(
      catchError(e =>
        of({ params: new URLSearchParams({ error: e.message }), redirectResponse: { appState: null, loggedIn: null } })
      ),
      concatMap(({ params, redirectResponse }) => {
        if (params.get('error')) {
          if (params.get('error').includes('Please verify your email before logging in.')) {
            const email = params.get('error').split(':')[0];
            return of({
              loggedIn: redirectResponse.loggedIn,
              targetUrl: environment.domain + `/auth/verify-email?email=${email}`,
              errorType: Auth0LoginErrorEnum.verifyEmail
            });
          } else {
            return of({ loggedIn: redirectResponse.loggedIn, targetUrl: '', errorType: Auth0LoginErrorEnum.unknown });
          }
        } else if (params.get('code') && params.get('state')) {
          return redirectResponse.loggedIn
            ? of({
                loggedIn: redirectResponse.loggedIn,
                targetUrl: redirectResponse.appState.target
              })
            : of({ loggedIn: redirectResponse.loggedIn, targetUrl: '/callback' });
        } else {
          return of({ loggedIn: redirectResponse.loggedIn, targetUrl: redirectResponse.appState.target });
        }
      })
    );
  }

  logout(redirectUrl: string = environment.auth0.logout_url) {
    this.auth0Client$.pipe(first()).subscribe((client: Auth0Client) => {
      client.logout({
        clientId: environment.auth0.client_id,
        logoutParams: {
          returnTo: redirectUrl
        }
      });
    });
  }
}
